import isServer from '@lyra/core/utils/isServer'
import { DepositNetwork } from '@lyra/web/constants/chains'
import { ALCHEMY_RPC_COOKIE_NAME } from '@lyra/web/constants/cookies'
import { createPublicClient, http } from 'viem'

import { getChainForDepositNetwork } from './chains'
import { generateRpcTokenSERVER } from './server/rpc'

const getRpcCookieCLIENT = () => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${ALCHEMY_RPC_COOKIE_NAME}=`)
  if (parts.length === 2) {
    // Expired cookie will not be sent once expired
    return parts.pop()!.split(';').shift()
  }
  return null
}

const _refreshAccessTokenCLIENT = async (): Promise<string> => {
  const response = await fetch('/api/rpc/refresh-token', { method: 'POST', cache: 'no-cache' })
  if (!response.ok) {
    throw new Error('Failed to refresh rpc access token')
  }
  const json = await response.json()
  return json.token
}

// Global lock for concurrent promises
let refreshAccessTokenPromise: Promise<string> | null
const refreshAccessTokenCLIENT = async (): Promise<string> => {
  // Set global if no concurrent request is occurring
  if (!refreshAccessTokenPromise) {
    refreshAccessTokenPromise = _refreshAccessTokenCLIENT()
  }
  const refreshedToken = await refreshAccessTokenPromise
  refreshAccessTokenPromise = null
  return refreshedToken
}

const getAccessToken = async (): Promise<string> => {
  if (isServer) {
    const { token } = await generateRpcTokenSERVER() // no-cost to regenerate jwt
    return token
  } else {
    const existingToken = getRpcCookieCLIENT()
    if (existingToken) {
      return existingToken
    }
    const refreshedToken = await refreshAccessTokenCLIENT()
    return refreshedToken
  }
}

export const getNetworkClient = async (network: DepositNetwork) => {
  const token = await getAccessToken()
  const chain = getChainForDepositNetwork(network)
  const client = createPublicClient({
    chain: chain,
    transport: http(chain.rpcUrls.default.http[0], {
      fetchOptions: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    }),
  })

  return client
}
